import React, { useCallback, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { isEmpty } from "lodash";

import AddJobView from "./views/AddJobView";
import { addJobSchema, jobDescriptionSchema } from "./addJobValidationSchema";

import LoginSelector from "../../../Login/selectors/LoginSelector";

import { logEvent } from "../../../../configs/firebase";
import {
  employerAddJobApi,
  employerJobUpdate,
  locationCheck,
} from "../../../../configs/network/apis";
import {
  showLoader,
  hideLoader,
  showNotification,
  equalsIgnoringCase,
  encodeHtml,
  decodeToHtml,
} from "../../../../utils/commonUtils";
import { deleteJobPost } from "../../actions/EmployerActions";

import useIsMobile from "../../../../hooks/useIsMobile";

import { EMPLOYER_PAGE_ROUTE, EMPLOYER_JOBS_PAGE_ROUTE } from "../../../../routes/routeConstants";
import AddJobMobileView from "./mobileViews/AddJobMobileView";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import EmployerSelector from "containers/Employer/selectors/EmployerSelector";

const AddJobContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const googleInputRef = useRef();
  const jobTitle = useRef("");

  const { state } = useLocation();
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [jobLocationFormattedAddress, setJobLocationFormattedAddress] = useState("");
  const [minAge, setMinAge] = useState(
    state?.data?.min_age !== undefined ? state?.data?.min_age : "",
  );
  const [showInactiveTerms, setShowInactiveTerms] = useState(false);
  const [showDeleteJob, setShowDeleteJob] = useState(false);
  const [showDuplicateJobAlert, setShowDuplicateJobAlert] = useState(false);
  const [activeStepAddEditJob, setActiveStepAddEditJob] = useState(1);
  const [showCancelLeaveModal, setShowCancelLeaveModal] = useState(false);
  const employerJobsList = useSelector(EmployerSelector.getEmployerListing);

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onSubmitAddJobPost = async (values) => {
    // added activeStepAddEditJob condition to ensure modal does not appear on first page
    if (!state?.isEditPress && isDuplicateJobPosting() && activeStepAddEditJob !== 1) {
      if (!showDuplicateJobAlert) {
        setShowDuplicateJobAlert(true);
        return;
      } else {
        setShowDuplicateJobAlert(false);
      }
    }
    if (activeStepAddEditJob === 1 && !isMobile) {
      setActiveStepAddEditJob((prev) => prev + 1);
      logEvent(FirebaseEvents.EMP_CLICKS_NEXT_ON_POST_JOB);
      return;
    }
    showLoader();
    const description = decodeToHtml(jobDescriptionFormik.values?.jobDescription) || "";
    if (isEmpty(description)) {
      jobDescriptionFormik.handleSubmit();
      hideLoader();
      return;
    }
    const {
      experience,
      jobLocation,
      address_locality,
      address_region,
      postal_code,
      address_country,
      jobTitle,
      note,
      wageExpectation,
      workingHours,
      lat,
      lng,
      timeSlot,
      daysSelected,
      status,
      min_age,
    } = values;
    try {
      if (state?.isEditPress) {
        const {
          success,
          data: { message },
        } = await employerJobUpdate(
          {
            address_country,
            address_locality,
            address_region,
            city: jobLocation,
            daysSelected,
            experience,
            hoursListed: workingHours,
            lat,
            lng,
            listingDescription: encodeHtml(description),
            listingPosition: jobTitle,
            min_age,
            note: note,
            postal_code,
            wage: wageExpectation,
            status,
            timeSlot,
          },
          state.data.jobId,
        );
        hideLoader();
        navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`);
        showNotification({
          alertProps: {
            severity: success ? "success" : "error",
            children: message,
          },
        });
      } else {
        const postedJob = await employerAddJobApi({
          hoursListed: workingHours,
          listingPosition: jobTitle,
          city: jobLocation,
          address_locality,
          address_region,
          postal_code,
          address_country,
          experience,
          lat,
          lng,
          listingDescription: encodeHtml(description),
          wage: wageExpectation,
          note: note,
          timeSlot,
          daysSelected,
          status: "active",
          min_age,
        });
        hideLoader();
        logEvent(FirebaseEvents.EMP_POST_JOB, {
          items: [{ userId: loggedInUser.uid, status: "success" }],
        });
        navigate(
          `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}?showJobDetailView=true&jobId=${postedJob?.data?.job_id}&brandName=${postedJob?.data?.brandName}&pJob=true`,
        );
        showNotification({
          alertProps: {
            severity: "success",
            children: "You have succesfully added job.",
          },
        });
        logEvent(FirebaseEvents.EMP_CLICKS_POST_JOB);
      }
    } catch (error) {
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong please try again.",
        },
      });
      hideLoader();
    }
  };

  const onPressCancel = () => {
    if (formik.dirty || jobDescriptionFormik.dirty) {
      setShowCancelLeaveModal(true);
    } else {
      state?.isSharedJob
        ? navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`)
        : navigate(-1);
    }
  };

  const handleCancelLeaveModal = (isCancelClicked) => {
    if (isCancelClicked) {
      setShowCancelLeaveModal(false);
    } else {
      state?.isSharedJob
        ? navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`)
        : navigate(-1);
    }
  };

  const handleWageChange = (value) => {
    formik.setFieldValue("wageExpectation", value);
  };

  const handleHoursChange = (value) => {
    formik.setFieldValue("workingHours", value);
  };
  const handleTimeSlotChange = (value) => {
    formik.setFieldValue("timeSlot", value);
  };

  const handleExpChange = (value) => {
    formik.setFieldValue("experience", value);
  };

  const handleDaysSelectedChange = (value) => {
    formik.setFieldValue("daysSelected", value);
  };

  const onBlurJobTitle = () => {
    jobTitle.current = formik.values.jobTitle;
    if (
      !state?.isEditPress &&
      state?.isCopy &&
      equalsIgnoringCase(state?.data?.city, jobLocationFormattedAddress) &&
      equalsIgnoringCase(formik.values.jobTitle, state?.data?.listingPosition)
    ) {
      setShowDuplicateJobAlert(true);
    }
  };

  const onPlaceSelected = async (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const jobLocation = place.formatted_address;
    const { addressComponents } = place;
    setJobLocationFormattedAddress(place.formatted_address);
    try {
      showLoader();
      await locationCheck({
        searchedLat: lat,
        searchedLng: lng,
        email: loggedInUser?.email,
        userId: loggedInUser.uid,
        locationString: jobLocation !== "Current Location" ? jobLocation : "",
        jobTitle: jobTitle.current,
        source: isMobile ? "resp" : "web",
        type: "Employer",
      });
      hideLoader();
      setInvalidLocation(false);
      formik.setFieldValue("lat", lat);
      formik.setFieldValue("lng", lng);
      formik.setFieldValue("jobLocation", jobLocation);
      formik.setFieldValue("address_locality", addressComponents.locality);
      formik.setFieldValue("address_region", addressComponents.administrative_area_level_1);
      formik.setFieldValue("postal_code", addressComponents.postal_code);
      formik.setFieldValue("address_country", addressComponents.country);
    } catch (e) {
      setInvalidLocation(true);
      hideLoader();
    }
  };

  const isDuplicateJobPosting = () => {
    // Case 1: duplicate job same as previous job
    if (
      state?.isCopy &&
      equalsIgnoringCase(state?.data?.city, formik.values.jobLocation) &&
      equalsIgnoringCase(state?.data?.listingPosition, formik.values.jobTitle)
    ) {
      return true;
    }
    // Case 2: New job exists
    let isDuplicateJob = false;
    employerJobsList?.forEach((job) => {
      if (
        equalsIgnoringCase(job?.city, formik.values.jobLocation) &&
        equalsIgnoringCase(job?.listingPosition, formik.values.jobTitle)
      ) {
        isDuplicateJob = true;
      }
    });
    return isDuplicateJob;
  };

  // Reset the Lat Lng and value of the field.
  const clearInput = () => {
    googleInputRef.current?.changeValue(null);
    formik.setFieldValue("lat", null);
    formik.setFieldValue("lng", null);
    formik.setFieldValue("jobLocation", "");
    formik.setFieldValue("address_locality", "");
    formik.setFieldValue("address_region", "");
    formik.setFieldValue("postal_code", "");
    formik.setFieldValue("address_country", "");
  };

  const onClickStatus = (status, reasonText) => async () => {
    showLoader();
    try {
      const {
        success,
        data: { message, status },
      } = await employerJobUpdate(
        {
          status,
          reason: reasonText,
        },
        state?.data?.jobId,
      );
      formik.setFieldValue("status", status);
      handleInactiveModal(false);
      hideLoader();
      showNotification({
        alertProps: {
          severity: success ? "success" : "error",
          children: message,
        },
      });
    } catch (e) {
      hideLoader();
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again later.",
        },
      });
    }
  };

  const handleInactiveModal = (value) => {
    setShowInactiveTerms(value);
  };

  const handleDeleteModal = (value, reasonForDeletingJob = "") => {
    if (value) {
      dispatch(
        deleteJobPost({
          jobId: state.data.jobId,
          deleteReason: reasonForDeletingJob,
          onCallback: handleDeleteCallBack,
        }),
      );
    } else {
      setShowDeleteJob(false);
    }
  };

  const handleDeleteCallBack = (params) => {
    hideLoader();
    setShowDeleteJob(false);
    const { deleteStatus, message } = params;
    showNotification({
      alertProps: {
        severity: deleteStatus ? "success" : "error",
        children: message,
      },
    });

    if (deleteStatus) {
      logEvent(FirebaseEvents.EMP_CLICKS_DELETE_JOB);
      navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`);
    }
  };

  const handleJobDescriptionChange = useCallback((html) => {
    jobDescriptionFormik.setFieldValue("jobDescription", html);
  }, []);

  const handleAgeChange = (SelectChangeEvent) => {
    setMinAge(SelectChangeEvent.target.value);
    formik.setFieldValue("min_age", SelectChangeEvent.target.value);
  };

  const formik = useFormik({
    initialValues: {
      jobTitle: state?.isEditPress || state?.isCopy ? state?.data?.listingPosition : "",
      jobLocation: state?.isEditPress ? state?.data?.city : "",
      address_locality: state?.isEditPress ? state?.data?.address_locality : "",
      address_region: state?.isEditPress ? state?.data?.address_region : "",
      postal_code: state?.isEditPress ? state?.data?.postal_code : "",
      address_country: state?.isEditPress ? state?.data?.address_country : "",
      lat: state?.isEditPress || state?.isCopy ? state?.data?.lat : "",
      lng: state?.isEditPress || state?.isCopy ? state?.data?.lng : "",
      note: state?.isEditPress || state?.isCopy ? state?.data?.note : "",
      wageExpectation: state?.isEditPress || state?.isCopy ? state?.data?.wage : "",
      workingHours: state?.isEditPress || state?.isCopy ? state?.data?.hoursListed : "",
      experience: state?.isEditPress || state?.isCopy ? state?.data?.experience : "",
      min_age: state?.isEditPress || state?.isCopy ? state?.data?.min_age : "",
      brandLogo: loggedInUser?.photoURL,
      timeSlot: state?.isEditPress || state?.isCopy ? state?.data?.timeSlot : "",
      daysSelected: state?.isEditPress || state?.isCopy ? state?.data?.daysSelected : "",
      status: state?.isEditPress || state?.isCopy ? state?.data?.status : "active",
    },
    validationSchema: addJobSchema,
    onSubmit: onSubmitAddJobPost,
  });

  const jobDescriptionFormik = useFormik({
    initialValues: {
      jobDescription:
        state?.isEditPress || state?.isCopy ? decodeToHtml(state?.data?.listingDescription) : "",
    },
    validationSchema: jobDescriptionSchema,
    onSubmit: () => {
      formik.handleSubmit();
    },
  });

  const AddJobWrapper = isMobile ? AddJobMobileView : AddJobView;

  return (
    <AddJobWrapper
      city={state?.data.city}
      isCopy={state?.isCopy}
      isEditPress={state?.isEditPress}
      formik={formik}
      jobDescriptionFormik={jobDescriptionFormik}
      googleInputRef={googleInputRef}
      jobLocationFormattedAddress={jobLocationFormattedAddress}
      invalidLocation={invalidLocation}
      loggedInUser={loggedInUser}
      minAge={minAge}
      showDeleteJob={showDeleteJob}
      showInactiveTerms={showInactiveTerms}
      showDuplicateJobAlert={showDuplicateJobAlert}
      showCancelLeaveModal={showCancelLeaveModal}
      clearInput={clearInput}
      onBlurJobTitle={onBlurJobTitle}
      onClickStatus={onClickStatus}
      onPressCancel={onPressCancel}
      onPlaceSelected={onPlaceSelected}
      handleAgeChange={handleAgeChange}
      handleDaysSelectedChange={handleDaysSelectedChange}
      handleDeleteModal={handleDeleteModal}
      handleExpChange={handleExpChange}
      handleHoursChange={handleHoursChange}
      handleInactiveModal={handleInactiveModal}
      handleTimeSlotChange={handleTimeSlotChange}
      handleWageChange={handleWageChange}
      handleJobDescriptionChange={handleJobDescriptionChange}
      handleCancelLeaveModal={handleCancelLeaveModal}
      setShowDeleteJob={setShowDeleteJob}
      setShowInactiveTerms={setShowInactiveTerms}
      setShowDuplicateJobAlert={setShowDuplicateJobAlert}
      setMinAge={setMinAge}
      activeStepAddEditJob={activeStepAddEditJob}
      setActiveStepAddEditJob={setActiveStepAddEditJob}
      setShowCancelLeaveModal={setShowCancelLeaveModal}
    />
  );
};

export default AddJobContainer;
