import { userDataDetails, userExistApi } from "../configs/network/apis";
import { store } from "../configs/store";
import {
  hideLoaderAction,
  showLoaderAction,
  hideNotificationAction,
  showNotificationAction,
} from "../containers/Common/actions/CommonActions";
import DOMPurify from "dompurify";
import * as Sentry from "@sentry/react";

export const showLoader = () => {
  store.dispatch(showLoaderAction());
};

export const hideLoader = () => {
  store.dispatch(hideLoaderAction());
};

export const showNotification = (payload) => {
  store.dispatch(showNotificationAction(payload));
};

export const hideNotification = (payload) => {
  store.dispatch(hideNotificationAction(payload));
};

export const setLoginRedirection = (uid, pathname, search, state) => {
  if (!uid)
    sessionStorage.setItem(
      "redirect_value",
      JSON.stringify({
        pathname,
        search,
        state,
      }),
    );
};

export const setClickedOnApplyButtonAction = () => {
  sessionStorage.setItem("isClickedOnApply", "true");
};
export const getClickedOnApplyButtonAction = () => {
  const actionValueForApplyButton = sessionStorage.getItem("isClickedOnApply") || "false";
  sessionStorage.removeItem("isClickedOnApply");
  return actionValueForApplyButton;
};

export const getRedirectValue = () => {
  const redirectValue = JSON.parse(sessionStorage.getItem("redirect_value") || "{}");
  sessionStorage.removeItem("redirect_value");
  return redirectValue;
};

export const websiteExists = async (website) => {
  website = formatWebsiteUrl(website);
  const CORS_PROXY = "https://corsproxy.io/?";

  try {
    let res = await fetch(CORS_PROXY + encodeURIComponent(website), {
      method: "HEAD",
    });
    return res.ok;
  } catch (error) {
    return false;
  }
};

export const formatWebsiteUrl = (url) => {
  if (!url.startsWith("http")) {
    url = "https://" + url;
  }
  const [protocol, sitename] = url.split("//");
  if (!sitename.startsWith("www.")) {
    url = protocol + "//www." + sitename;
  }
  return url;
};

export const getFormattedJobLocation = (location) => {
  if (!location || location.length === 1) {
    return location;
  }
  let locationParts = location.split(", ");
  return locationParts.slice(-3).join(", ");
};

export const getProcessedJobDetailValue = (valueList) => {
  if (!valueList) {
    return null;
  }
  if (valueList.length === 1) {
    return valueList[0];
  }
  if (valueList.length > 1) {
    let valueString = valueList.toString();
    let result = valueString.match(/\d+/g);
    return `${result[0]}-${result[result.length - 1]}`;
  }
};

export const getProcessedExperienceValue = (valueList) => {
  if (!valueList) {
    return null;
  }
  if (valueList.length === 1) {
    return valueList[0] === "0" ? "Beginner" : `${valueList[0]} Years`;
  }
  if (valueList.length > 1) {
    let valueString = valueList.toString();
    let result = valueString.match(/\d+/g);
    return `${result[0]}-${result[result.length - 1]} Years`;
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getBase64FromUrl = async (imgUrl) => {
  const res = await fetch(imgUrl, { referrerPolicy: "no-referrer" });
  const blob = await res.blob();
  const result = await getBase64(blob);
  return result;
};

export const isIos = () => {
  const iosKeywords = [
    "iPhone",
    "iPad",
    "iPod",
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
  ];
  return (
    iosKeywords.some((keyword) => navigator.userAgent.includes(keyword)) ||
    (navigator.userAgent.includes("Mac") && "ontouchend" in document) // iPad on iOS 13 detection
  );
};

export const convertArrayIntoChunks = (inputArray, perChunk = 5) =>
  inputArray.reduce((arr, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);
    if (!arr[chunkIndex]) {
      arr[chunkIndex] = [];
    }
    arr[chunkIndex].push(item);
    return arr;
  }, []);

/**
 * Phone Number validation
 */
export const isValidPhoneNumber = async ({ countryCode, phoneNumber }) => {
  const countryCodeMap = {
    91: "IN",
    1: "US",
  };

  const { PhoneNumberUtil } = await import("google-libphonenumber");
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    const parsedNumber = phoneUtil.parse(phoneNumber, countryCodeMap[countryCode]);
    return phoneUtil.isValidNumber(parsedNumber);
  } catch (err) {
    return false;
  }
};

/**
 * noopener windows navigation
 * @param {navigation url} navPath
 */
export const navigateWindow = (navPath) => {
  window.open(navPath, "_blank");
};

export const getSanitizedHtml = (html) => {
  return DOMPurify.sanitize(html, { FORCE_BODY: true });
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  value === isNaN(value) ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export const urlShortener = async (longURL = "") => {
  const apiUrl = `https://api.shrtco.de/v2/shorten?url=${longURL}`;
  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data.result.full_short_link;
  } catch (e) {
    console.error(e);
  }
};

/**
 * Case insensitive string comparison
 */
export const equalsIgnoringCase = (text, target) => {
  return text.localeCompare(target, undefined, { sensitivity: "base" }) === 0;
};

export const maskWord = (word) => {
  return "*".repeat(word.length - 2) + word.slice(-2);
};

export const maskEmail = (email) => {
  let emailSplit = email.split("@");
  return maskWord(emailSplit[0]) + "@" + emailSplit[1];
};

export const maskPhone = (phone) => {
  let phoneSplit = phone.split("-");
  return phoneSplit[0] + "-" + maskWord(phoneSplit[1]);
};

export const getSanitizedFullName = (fullName) => {
  // Remove any characters that are not alphabets, single space, ', and .
  // Cannot start with space, . and '
  // double spaces not allowed
  return fullName.replace(/[^A-Za-z '.]|(?<=\s)\s+|^\s+|^\.+|^'+/g, "");
};

export const isKeyAllowedInFullName = (key) => {
  // Only alphabets, space, . and ' are allowed
  const allowedCharactersPattern = /[a-zA-Z.\s']/;
  return allowedCharactersPattern.test(key);
};

export const getIsAccountDeleted = async (userId, userType) => {
  try {
    await userDataDetails({
      uid: userId,
      type: userType,
    });
    return false;
  } catch (error) {
    const errorResponse = error?.response?.data;
    if (errorResponse?.status === 404 && errorResponse?.code === "account_not_exist") {
      return true;
    }
  }
};

export const getBlockUsersList = async (loggedInUser) => {
  const {
    data: {
      user: { blockedByIds, blockedIds },
    },
  } = await userDataDetails({
    uid: loggedInUser?.uid,
    type: loggedInUser?.userType,
  });

  return blockedByIds.concat(blockedIds);
};

export const handleAsync = (callback) => {
  // Wrapper function to address following Sonar issue:
  // Promise-returning function provided to attribute where a void return was expected.
  return () => {
    // Immediately invoke the callback function
    (async () => {
      await callback();
    })();
  };
};

/**
 * Encode html to base64 string
 * @param {string} html
 * @returns string
 */
export const encodeHtml = (html) => btoa(html);

/**
 * Decode string to html if its base64 encoded, otherwise return the string as it is
 * @param {string} html
 * @returns string
 */
export const decodeToHtml = (encodedString) => {
  try {
    // Attempt to decode the string
    const decodedHtml = atob(encodedString);
    // Encode the decoded string back to Base64
    const reencodedString = btoa(decodedHtml);
    // Check if the re-encoded string matches the original input (to handle non-Base64 inputs)
    return reencodedString === encodedString ? decodedHtml : encodedString;
  } catch (error) {
    // Catch and return false for any decoding errors, since string is not Base64 encoded
    return encodedString;
  }
};

export const checkIsDetailView = (location) => {
  return (
    location.search.includes("showJobDetailView=true") ||
    location.search.includes("showPeopleDetailView=true")
  );
};

//TODO : remove this and handle at filter apply level
export const getFilterCount = (key) => {
  //This helper function is for getting count excluding 'all' in filters
  return key?.includes("all") ? key?.length - 1 : key?.length;
};

//TODO : make this a generic function to handle non array fields also
export const activeFiltersCount = (filterSettings) => {
  return Object.values(filterSettings || {})
    .filter(Array.isArray)
    .filter((value) => value.length > 0).length;
};

export const checkEmailExists = async (email) => {
  try {
    await userExistApi({ keyValue: email, keyType: "email" });
    return true;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return false;
    }
    showNotification({
      alertProps: {
        severity: "error",
        children: "An unexpected error occurred. Please try again.",
      },
    });
    Sentry.captureException(error.response);
    return true;
  }
};
